export const paths = {
    home: {
        path: '/',
        getHref: () => '/',
        clicker: {
            path: '/clicker',
            getHref: () => '/clicker',
        },
        leagues: {
            path: '/leagues',
            getHref: () => '/leagues',
        },
        boosts: {
            path: '/boosts',
            getHref: () => '/boosts',
        },
    },

    tasks: {
        cinema: {
            path: '/tasks/cinema',
            getHref: () => '/tasks/cinema',
            task: {
                path: '/tasks/cinema/:cinemaTaskId',
                getHref: (cinemaTaskId: string) => `/tasks/cinema/${cinemaTaskId}`,
            },
        },
        special: {
            path: '/tasks/special',
            getHref: () => '/tasks/special',
            task: {
                path: '/tasks/special/:specialTaskId',
                getHref: (specialTaskId: string) => `/tasks/special/${specialTaskId}`,
            },
        },
        leagues: {
            path: '/tasks/leagues',
            getHref: () => '/tasks/leagues',
        },
        referrals: {
            path: '/tasks/referrals',
            getHref: () => '/tasks/referrals',
        },
    },
    game: {
        root: {
            path: '/game',
            getHref: () => '/game',
        },
        shopItemPurchaseHistory: {
            path: '/game/purchase-history',
            getHref: () => '/game/purchase-history',
        },
    },
    daily: {
        root: {
            path: '/daily',
            getHref: () => '/daily',
        },
    },
    account: {
        referrals: {
            path: '/account/referrals',
            getHref: () => '/account/referrals',
        },
        stats: {
            path: '/stats',
            getHref: () => '/stats',
        },
        settings: {
            path: '/settings',
            getHref: () => '/settings',
        },
    },
} as const;