import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

// グローバルアナウンス関連の型定義
export interface GlobalAnnouncement {
    globalAnnouncementId: string;
    title: string;
    description: string;
    imageUrl?: string;
    ctaButtonText?: string;
    ctaButtonUrl?: string;
    priority: number;
}

// グローバルアナウンスモーダルコンポーネント
export interface GlobalAnnouncementModalProps {
    isOpen: boolean;
    onClose: () => void;
    announcement: GlobalAnnouncement;
}

const GlobalAnnouncementModal = ({ isOpen, onClose, announcement }: GlobalAnnouncementModalProps) => {
    const [isClosing, setIsClosing] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsMounted(true);
        }
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
            setIsMounted(false);
        }, 300);
    };

    if (!isMounted) return null;

    return createPortal(
        <div
            className={`fixed inset-0 z-[980] flex items-end justify-center
            ${isOpen ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        >
            {/* Backdrop */}
            <div
                className={`absolute inset-0 bg-black ${isOpen && !isClosing ? 'opacity-50' : 'opacity-0'}
                transition-opacity duration-300`}
                onClick={handleClose}
            />

            {/* Modal */}
            <div
                className="relative w-full bg-white rounded-t-2xl shadow-lg overflow-hidden h-[75vh]"
                style={{
                    transform: isOpen && !isClosing ? 'translateY(0)' : 'translateY(100%)',
                    transition: 'transform 0.3s ease'
                }}
            >
                {/* Close button */}
                <button
                    onClick={handleClose}
                    className="absolute right-4 top-4 p-2 bg-white hover:bg-gray-100 rounded-full transition-colors duration-200 z-10 shadow-md"
                >
                    <X className="w-6 h-6 text-gray-500" />
                </button>

                {/* Content */}
                <div className="h-full flex flex-col relative font-mplus1p">
                    <div className="flex-1 overflow-y-auto pb-20">
                        {/* Image */}
                        {announcement.imageUrl && (
                            <div className="w-full h-48 bg-gray-200">
                                <img
                                    src={announcement.imageUrl}
                                    alt={announcement.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        )}

                        {/* Text Content */}
                        <div className="p-6 space-y-4">
                            {/* Title */}
                            <h3 className="text-lg font-mplus1p font-semibold text-gray-900 text-left pt-4">
                                {announcement.title}
                            </h3>

                            {/* Description */}
                            <p className="text-sm font-mplus1p text-gray-600 text-left whitespace-pre-line">
                                {announcement.description}
                            </p>
                        </div>
                        {/* Bottom safe area spacing for mobile */}
                        <div className="h-[env(safe-area-inset-bottom)]" />
                    </div>

                    {/* Sticky CTA Button */}
                    {announcement.ctaButtonText && (
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
                            {announcement.ctaButtonUrl ? (
                                <Link
                                    to={announcement.ctaButtonUrl}
                                    className={`
                                        block w-full py-3 rounded-lg font-light
                                        bg-[#DE6437] hover:bg-[#C55832] text-white
                                        transform hover:scale-105
                                        transition-all duration-200
                                        focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2
                                        text-center
                                    `}
                                    onClick={handleClose}
                                >
                                    <div className="flex items-center justify-center space-x-2 font-mplus1p">
                                        <span>{announcement.ctaButtonText}</span>
                                        <ExternalLink className="w-3 h-3" />
                                    </div>
                                </Link>
                            ) : (
                                <button
                                    onClick={handleClose}
                                    className={`
                                        w-full py-3 rounded-lg font-light
                                        bg-[#DE6437] hover:bg-[#C55832] text-white
                                        transform hover:scale-105
                                        transition-all duration-200
                                        focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2
                                    `}
                                >
                                    <div className="flex items-center justify-center space-x-2 font-mplus1p">
                                        <span>{announcement.ctaButtonText}</span>
                                    </div>
                                </button>
                            )}

                            {/* Bottom safe area spacing for mobile */}
                            <div className="h-[env(safe-area-inset-bottom)]" />
                        </div>
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default GlobalAnnouncementModal;