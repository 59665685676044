import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { paths } from '../config/paths';
import { AppRoot, AppRootErrorBoundary } from './routes/root';
import { ClickerRoute } from './routes/clicker/clicker';
import { LeaguesRoute } from './routes/clicker/leagues';
import { ItemsRoute } from './routes/clicker/items';
import { CinemaRoute } from './routes/tasks/cinema/cinema';
import { CinemaTaskRoute } from './routes/tasks/cinema/[cinemaTaskId]';
import { SpecialRoute } from './routes/tasks/special/special';
import { SpecialTaskRoute } from './routes/tasks/special/[specialTaskId]';
import { LeaguesTaskRoute } from './routes/tasks/leagues/leagues';
import { ReferralsTaskRoute } from './routes/tasks/referrals/referrals';
import { GameRoute } from './routes/game/root';
import { ShopItemPurchaseHistoryRoute } from './routes/game/ShopItemPurchaseHistory/ShopItemPurchaseHistory';
import { ReferralsRoute } from './routes/account/referrals/referrals';
import { StatsRoute } from './routes/account/stats/stats';
import { SettingsRoute } from './routes/account/settings/settings';
import { NotFoundRoute } from './routes/not-found';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    return children;
};

const router = createBrowserRouter([
    {
        path: paths.home.path,
        element: (
            <ProtectedRoute>
                <AppRoot />
            </ProtectedRoute>
        ),
        children: [
            { index: true, element: <Navigate to={paths.home.clicker.path} replace /> },
            {
                path: paths.home.clicker.path,
                element: <ClickerRoute />,
            },
            {
                path: paths.home.leagues.path,
                element: <LeaguesRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.home.boosts.path,
                element: <ItemsRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.cinema.path,
                element: <CinemaRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.cinema.task.path,
                element: <CinemaTaskRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.special.path,
                element: <SpecialRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.special.task.path,
                element: <SpecialTaskRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.leagues.path,
                element: <LeaguesTaskRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.tasks.referrals.path,
                element: <ReferralsTaskRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.game.root.path,
                element: <GameRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.game.shopItemPurchaseHistory.path,
                element: <ShopItemPurchaseHistoryRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.account.referrals.path,
                element: <ReferralsRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.account.stats.path,
                element: <StatsRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
            {
                path: paths.account.settings.path,
                element: <SettingsRoute />,
                ErrorBoundary: AppRootErrorBoundary,
            },
        ],
    },
    {
        path: '*',
        element: <NotFoundRoute />,
    },
]);

export const AppRouter = () => {
    return <RouterProvider router={router} />;
};