import { atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { GetShopItemsResponse } from '../../functions/api/v1/users/shops/get_items/type';

// 拡張されたCoinCalculationの型定義
interface ExtendedSpentCoins {
    tapBotUpgrades: number;
    tapLevelUpgrades: number;
    energyLimitUpgrades: number;
    rechargeSpeedUpgrades: number;
    boostItemPurchases: number;
    dailyCheckinPurchases: number;
    fullChargePurchases: number;
    shopItemPurchases: number;
    operationalCoinsDeduction: number;
    totalSpent: number;
}

interface ExtendedEarnedCoins {
    clickEarned: number;
    botTotalEarned: number;
    cinemaTaskRewards: number;
    specialTaskRewards: number;
    leagueTaskRewards: number;
    referralTaskRewards: number;
    dailyCheckinRewards: number;
    operationalCoins: number;
    itemBonusCoins: number;
    totalEarned: number;
}

interface ExtendedCoinCalculation {
    earnedCoins: ExtendedEarnedCoins;
    spentCoins: ExtendedSpentCoins;
    totalCoins: number;
}

const sessionStorage = () => {
    if (typeof window !== "undefined") {
        return createJSONStorage<boolean>(() => window.sessionStorage);
    }
    return undefined;
};

import { initData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import {
    PostUsersChallenge,
    GlobalContextResponse,
    CurrencyMaster,
    FullChargeHistory,
    BoostHistory,
    DailyCheckinState,
} from '../../functions/api/v1/users/challenge/type';
import {
    LeagueMaster,
    TapBotLevelMaster,
    TapLevelMaster,
    EnergyLimitLevelMaster,
    RechargeSpeedLevelMaster,
    MultipliesBoostItem,
    CinemaTask,
    SpecialTask,
    LeagueTask,
    ReferralTask,
    DailyCheckinItem,
    DailyCheckinReward,
    FullChargeMaster,
    DailyMetricsSummary,
    MasterContextResponse
} from '../../functions/api/v1/master/type';
import { GlobalAnnouncement } from '../components/Annoucement/Global/GlobalAnnouncementModal';
import {
    PurchaseRequestBody,
    PurchaseResponse
} from '../../functions/api/v1/users/purchase/clicker_items/type';
import { ClickRequest, GameError, GameErrorCode, UpdateDailyCheckinReward } from '../../functions/api/v1/users/click/type';

import { PostUsersCinemaTaskResponse } from '../../functions/api/v1/users/tasks/cinema/[cinemaTaskId]/type';
import { PostUsersCinemaTaskItemResponse } from '../../functions/api/v1/users/tasks/cinema/[cinemaTaskId]/[cinemaTaskItemId]/type';
import { PostUsersCinemaTaskClaimResponse } from '../../functions/api/v1/users/tasks/cinema/[cinemaTaskId]/claim/type';

import { PostUsersSpecialTaskResponse } from '../../functions/api/v1/users/tasks/special/[specialTaskId]/type';
import { PostUsersSpecialTaskItemResponse } from '../../functions/api/v1/users/tasks/special/[specialTaskId]/[specialTaskItemId]/type';
import { PostUsersSpecialTaskClaimResponse } from '../../functions/api/v1/users/tasks/special/[specialTaskId]/claim/type';

import { PostUsersLeagueTaskClaimResponse } from '../../functions/api/v1/users/tasks/league/[leagueTaskId]/claim/type';

import { PostUsersReferralTaskClaimResponse } from '../../functions/api/v1/users/tasks/ref/[referralTaskId]/claim/type';

import { PostUsersDailyCheckinResponse } from '../../functions/api/v1/users/checkin/type';

// Base atoms
export const accessTokenAtom = atomWithStorage<string>('access-token', '');

export const userBaseAtom = atom<{
    telegramId: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    languageCode?: string;
}>({
    telegramId: '',
});

// TapBotの収益モーダルの表示可否を制御するatom
export const tapBotEarningsModalFlagAtom = atomWithStorage(
    'tapbot-earnings-modal-flag',
    true,
    sessionStorage(),
    { getOnInit: true }
);

interface ClickerState {
    clicks: number;
    energy: number;
    maxEnergy: number;
    rechargeSpeed: number;
    freeBoostDosageLimit: number;
    freeRechargeLimit: number;
    lastSyncTime: number;
}

// ローカルステート用の型定義
// CoinCalculationの型を拡張
interface ExtendedCoinCalculation {
    earnedCoins: {
        clickEarned: number;
        botTotalEarned: number;
        cinemaTaskRewards: number;
        specialTaskRewards: number;
        leagueTaskRewards: number;
        referralTaskRewards: number;
        dailyCheckinRewards: number;
        operationalCoins: number;
        itemBonusCoins: number;
        totalEarned: number;
    };
    spentCoins: {
        tapBotUpgrades: number;
        tapLevelUpgrades: number;
        energyLimitUpgrades: number;
        rechargeSpeedUpgrades: number;
        boostItemPurchases: number;
        dailyCheckinPurchases: number;
        fullChargePurchases: number;
        shopItemPurchases: number;
        operationalCoinsDeduction: number;
        totalSpent: number;
    };
    totalCoins: number;
}

interface LocalClickerState {
    isInitialized: boolean;
    pendingClicks: number;
    lastClickTime: number;
    localEarnedCoins: number;
    localEnergy: number;
    lastSyncTime: number;
    lastEnergySync: number;
    localCoinCalculation: ExtendedCoinCalculation;
}

// エラー状態の型定義
interface ClickerError {
    code: GameErrorCode;
    message: string;
}

// タスク進捗の基本インターフェース
interface BaseTaskProgress {
    taskId: string;
    rewardClaimed: boolean;
    rewardCoins: number;
    completedAt?: number;
    createdAt: number;
}

// タスクアイテムの進捗インターフェース
interface TaskItemProgress {
    itemId: string;
    completedAt?: number;
    createdAt: number;
}

// 各タスクタイプの進捗インターフェース
interface CinemaTaskProgress extends BaseTaskProgress {
    taskItems: TaskItemProgress[];
}

interface SpecialTaskProgress extends BaseTaskProgress {
    taskItems: TaskItemProgress[];
}

interface LeagueTaskProgress extends BaseTaskProgress { }
interface ReferralTaskProgress extends BaseTaskProgress { }

// タスク進捗状態の管理用atom
export const taskProgressAtom = atom<{
    cinemaTasks: CinemaTaskProgress[];
    specialTasks: SpecialTaskProgress[];
    leagueTasks: LeagueTaskProgress[];
    referralTasks: ReferralTaskProgress[];
}>({
    cinemaTasks: [],
    specialTasks: [],
    leagueTasks: [],
    referralTasks: [],
});

export interface CurrentLeague extends LeagueMaster {
    isDarkStage: boolean;
}

// Derived atom for current league calculation
export const currentLeagueAtom = atom<CurrentLeague>((get) => {
    const masterData = get(masterDataAtom);
    const localState = get(localClickerStateAtom);

    // Dark mode league IDs
    const DARK_MODE_LEAGUE_STAGE_IDS: number[] = [1];

    // Calculate total earnings from local state
    const totalEarned = localState.localCoinCalculation.earnedCoins.clickEarned + localState.localCoinCalculation.earnedCoins.botTotalEarned;

    // リーグを必要コイン数で降順ソート
    const sortedLeagues = [...masterData.leagues].sort(
        (a, b) => b.requiredClickEarnedCoins - a.requiredClickEarnedCoins
    );

    const league = sortedLeagues.find(
        league => totalEarned >= league.requiredClickEarnedCoins
    ) || masterData.leagues[0];

    return {
        ...league,
        isDarkStage: league && DARK_MODE_LEAGUE_STAGE_IDS.includes(league.leagueId)
    };
});

// アイテム履歴用のatom
export const itemHistoriesAtom = atom<{
    fullChargeHistories: FullChargeHistory[];
    boostHistories: BoostHistory[];
}>({
    fullChargeHistories: [],
    boostHistories: [],
});

export const localClickerStateAtom = atomWithStorage<LocalClickerState>('local-clicker-state', {
    isInitialized: false,
    pendingClicks: 0,
    lastClickTime: 0,
    localEarnedCoins: 0,
    localEnergy: 0,
    lastSyncTime: Date.now(),
    lastEnergySync: Date.now(),
    localCoinCalculation: {
        earnedCoins: {
            clickEarned: 0,
            botTotalEarned: 0, // Add bot earned coins
            cinemaTaskRewards: 0,
            specialTaskRewards: 0,
            leagueTaskRewards: 0,
            referralTaskRewards: 0,
            dailyCheckinRewards: 0,
            operationalCoins: 0,
            itemBonusCoins: 0,
            totalEarned: 0
        },
        spentCoins: {
            tapBotUpgrades: 0,
            tapLevelUpgrades: 0,
            energyLimitUpgrades: 0,
            rechargeSpeedUpgrades: 0,
            boostItemPurchases: 0,
            dailyCheckinPurchases: 0,
            fullChargePurchases: 0,
            shopItemPurchases: 0,
            operationalCoinsDeduction: 0,
            totalSpent: 0
        },
        totalCoins: 0
    }
});

// エラー状態用のatom
export const clickerErrorAtom = atom<ClickerError | null>(null);
export const isDarkStageAtom = atom<boolean>(false);

type CoinsState = {
    clickEarnedCoins: number;
    botEarnedCoins: number;
    calculation: {
        earnedCoins: {
            clickEarned: number;
            botTotalEarned: number;
            cinemaTaskRewards: number;
            specialTaskRewards: number;
            leagueTaskRewards: number;
            referralTaskRewards: number;
            dailyCheckinRewards: number;
            operationalCoins: number;
            itemBonusCoins: number;
            totalEarned: number;
        };
        spentCoins: {
            tapBotUpgrades: number;
            tapLevelUpgrades: number;
            energyLimitUpgrades: number;
            rechargeSpeedUpgrades: number;
            boostItemPurchases: number;
            dailyCheckinPurchases: number;
            fullChargePurchases: number;
            shopItemPurchases: number;
            operationalCoinsDeduction: number;
            totalSpent: number;
        };
        totalCoins: number;
    };
};

export const coinsAtom = atom<CoinsState>({
    clickEarnedCoins: 0,
    botEarnedCoins: 0,
    calculation: {
        earnedCoins: {
            clickEarned: 0,
            botTotalEarned: 0, // Add bot earned coins
            cinemaTaskRewards: 0,
            specialTaskRewards: 0,
            leagueTaskRewards: 0,
            referralTaskRewards: 0,
            dailyCheckinRewards: 0,
            operationalCoins: 0,
            itemBonusCoins: 0,
            totalEarned: 0
        },
        spentCoins: {
            tapBotUpgrades: 0,
            tapLevelUpgrades: 0,
            energyLimitUpgrades: 0,
            rechargeSpeedUpgrades: 0,
            boostItemPurchases: 0,
            dailyCheckinPurchases: 0,
            fullChargePurchases: 0,
            shopItemPurchases: 0,
            operationalCoinsDeduction: 0,
            totalSpent: 0
        },
        totalCoins: 0
    }
});


export const userStatusAtom = atom<{
    isPremium: boolean;
    isBanned: boolean;
    banReason?: string;
    bannedAt?: number;
}>({
    isPremium: false,
    isBanned: false,
});

export const userTimestampsAtom = atom<{
    createdAt: number;
    lastLoginAt?: number;
    lastActiveAt?: number;
}>({
    createdAt: 0,
});

interface ReferralChild {
    anonymizedUserId: string;
    country: string;
    registeredAt: number;
}

export const referralsAtom = atom<{
    referralCode: string;
    friendBotId?: string;
    referredBy?: string | null;
    children: ReferralChild[];
}>({
    referralCode: '',
    children: [],
});

// Clean up and standardize the atoms
export const clickerAtom = atom<ClickerState>({
    clicks: 0,
    energy: 0,
    maxEnergy: 0,
    rechargeSpeed: 0,
    freeBoostDosageLimit: 0,
    freeRechargeLimit: 0,
    lastSyncTime: Date.now()
});

export const levelsAtom = atom<{
    tapLevel: number;
    tapBotLevel?: number;
    energyLimitLevel: number;
    rechargeSpeedLevel: number;
    cinemaTaskLevel: number;
    specialTaskLevel: number;
    leagueTaskLevel: number;
    referralTaskLevel: number;
}>({
    tapLevel: 0,
    tapBotLevel: undefined,
    energyLimitLevel: 0,
    rechargeSpeedLevel: 0,
    cinemaTaskLevel: 0,
    specialTaskLevel: 0,
    leagueTaskLevel: 0,
    referralTaskLevel: 0,
});

// boostに関連する型定義
interface Boost {
    userId: string;
    effectiveUntil: number;
    effectiveAt: number;
    multiplier: number;
}

// boostAtom
export const boostAtom = atom<Boost>({
    userId: '',
    effectiveUntil: 0,
    effectiveAt: 0,
    multiplier: 1,
});

interface TapBotState {
    isActive: boolean;
    level?: number;
    botClicks: number;
    botEarnedCoins: number;
    tapBotEarnStart?: number;
    tapBotEarnUntil?: number;
}

export const tapBotStateAtom = atom<TapBotState>({
    isActive: false,
    botClicks: 0,
    botEarnedCoins: 0
});

export const masterDataAtom = atom<{
    currencies: CurrencyMaster[];
    leagues: LeagueMaster[];
    tapBotLevels: TapBotLevelMaster[];
    tapLevels: TapLevelMaster[];
    energyLimitLevels: EnergyLimitLevelMaster[];
    rechargeSpeedLevels: RechargeSpeedLevelMaster[];
    multipliesBoostItems: MultipliesBoostItem[];
    fullChargeItems: FullChargeMaster[];  // 追加
    cinemaTasks: CinemaTask[];
    specialTasks: SpecialTask[];
    leagueTasks: LeagueTask[];
    referralTasks: ReferralTask[];
    dailyCheckinItems: DailyCheckinItem[];
    dailyCheckinRewards: DailyCheckinReward[];
    globalAnnouncements: GlobalAnnouncement[];
}>({
    currencies: [],
    leagues: [],
    tapBotLevels: [],
    tapLevels: [],
    energyLimitLevels: [],
    rechargeSpeedLevels: [],
    multipliesBoostItems: [],
    fullChargeItems: [],
    cinemaTasks: [],
    specialTasks: [],
    leagueTasks: [],
    referralTasks: [],
    dailyCheckinItems: [],
    dailyCheckinRewards: [],
    globalAnnouncements: [],
});

// UI State atoms
export const isInitLoadingAtom = atom<boolean>(false);
export const errorAtom = atom<string | null>(null);

export const currentEnergyAtom = atom((get) => {
    const clicker = get(clickerAtom);
    const lastSyncTime = clicker.lastSyncTime ?? Date.now();
    const energyRecharged = Math.min(
        clicker.maxEnergy - clicker.energy,
        Math.floor((Date.now() - lastSyncTime) / 1000) * clicker.rechargeSpeed
    );
    return Math.min(clicker.maxEnergy, clicker.energy + energyRecharged);
});


export const purchaseActionAtom = atom(null, async (get, set, purchaseRequest: PurchaseRequestBody) => {
    const accessToken = get(accessTokenAtom);
    try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/purchase/clicker_items`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(purchaseRequest)
        });

        if (!response.ok) throw new Error('Purchase failed');
        const purchaseData = await response.json() as PurchaseResponse;

        // Update based on purchase type
        switch (purchaseData.itemType) {
            case 'energy_limit':
                set(levelsAtom, prev => ({
                    ...prev,
                    energyLimitLevel: purchaseData.newLevel
                }));
                set(clickerAtom, prev => ({
                    ...prev,
                    maxEnergy: purchaseData.newEnergyLimit,
                }));
                break;

            case 'recharge_speed':
                set(levelsAtom, prev => ({
                    ...prev,
                    rechargeSpeedLevel: purchaseData.newLevel
                }));
                set(clickerAtom, prev => ({
                    ...prev,
                    rechargeSpeed: purchaseData.newRechargeSpeed
                }));
                break;

            case 'tap_level':
                set(levelsAtom, prev => ({
                    ...prev,
                    tapLevel: purchaseData.newLevel
                }));
                break;

            case 'full_charge':
                set(clickerAtom, prev => ({
                    ...prev,
                    energy: purchaseData.currentEnergy
                }));
                break;

            case 'boost':
                if (purchaseData.boost) {
                    set(boostAtom, purchaseData.boost);
                }
                break;

            case 'tapbot': {
                const masterData = get(masterDataAtom); // masterDataAtomからデータを取得
                const tapBotLevel = masterData.tapBotLevels.find(
                    level => level.level === purchaseData.level
                );

                if (tapBotLevel?.clickerItemsLvMax) {
                    // Premium TapBot購入時の最大レベル設定
                    const maxTapLevel = masterData.tapLevels[masterData.tapLevels.length - 1];
                    const maxEnergyLevel = masterData.energyLimitLevels[masterData.energyLimitLevels.length - 1];
                    const maxRechargeLevel = masterData.rechargeSpeedLevels[masterData.rechargeSpeedLevels.length - 1];

                    set(levelsAtom, prev => ({
                        ...prev,
                        tapBotLevel: purchaseData.level,
                        tapLevel: maxTapLevel.level,
                        energyLimitLevel: maxEnergyLevel.level,
                        rechargeSpeedLevel: maxRechargeLevel.level
                    }));

                    set(clickerAtom, prev => ({
                        ...prev,
                        maxEnergy: maxEnergyLevel.energyLimit,
                        rechargeSpeed: maxRechargeLevel.rechargeCoinPerSec
                    }));
                } else {
                    set(levelsAtom, prev => ({
                        ...prev,
                        tapBotLevel: purchaseData.level
                    }));
                }

                set(tapBotStateAtom, {
                    isActive: true,
                    level: purchaseData.level,
                    botClicks: 0,
                    botEarnedCoins: 0,
                    tapBotEarnStart: purchaseData.tapBotEarnStart,
                    tapBotEarnUntil: purchaseData.tapBotEarnUntil
                });
                break;
            }
        }

        // Update histories
        if (purchaseData.itemHistories) {
            set(itemHistoriesAtom, purchaseData.itemHistories);
        }

        // Update coins with botEarned calculation
        // 総獲得コインの計算
        // Update coins with correct calculation from server response
        const totalEarned = purchaseData.coins.earnedCoins.clickEarned +
            purchaseData.coins.earnedCoins.botTotalEarned +
            purchaseData.coins.earnedCoins.cinemaTaskRewards +
            purchaseData.coins.earnedCoins.specialTaskRewards +
            purchaseData.coins.earnedCoins.leagueTaskRewards +
            purchaseData.coins.earnedCoins.referralTaskRewards +
            purchaseData.coins.earnedCoins.dailyCheckinRewards +
            purchaseData.coins.earnedCoins.operationalCoins +
            purchaseData.coins.earnedCoins.itemBonusCoins;

        // Update coins with server response
        set(coinsAtom, {
            clickEarnedCoins: purchaseData.coins.earnedCoins.clickEarned,
            botEarnedCoins: purchaseData.coins.earnedCoins.botTotalEarned,
            calculation: {
                earnedCoins: {
                    ...purchaseData.coins.earnedCoins,
                    totalEarned,
                },
                spentCoins: purchaseData.coins.spentCoins,
                totalCoins: totalEarned - purchaseData.coins.spentCoins.totalSpent
            }
        });

        // Update local state with server response
        set(localClickerStateAtom, prev => ({
            ...prev,
            localEarnedCoins: purchaseData.coins.earnedCoins.clickEarned,
            localEnergy: purchaseData.itemType === 'full_charge' ?
                purchaseData.currentEnergy :
                prev.localEnergy,
            localCoinCalculation: {
                earnedCoins: purchaseData.coins.earnedCoins,
                spentCoins: purchaseData.coins.spentCoins,
                totalCoins: totalEarned - purchaseData.coins.spentCoins.totalSpent
            },
            lastSyncTime: Date.now(),
            lastEnergySync: purchaseData.itemType === 'full_charge' ?
                Date.now() :
                prev.lastEnergySync
        }));

        return purchaseData;
    } catch (error) {
        set(errorAtom, error instanceof Error ? error.message : 'Purchase failed');
        throw error;
    }
});

// Action atom for updating task progress
export const updateTaskProgressAtom = atom(
    null,
    (get, set, action: {
        type: 'UPDATE_TASK_PROGRESS';
        payload: {
            taskType: 'cinema' | 'special' | 'league' | 'referral';
            taskId: string;
            progress: Partial<CinemaTaskProgress>;
        };
    }) => {
        const currentProgress = get(taskProgressAtom);

        switch (action.payload.taskType) {
            case 'cinema': {
                const existingTaskIndex = currentProgress.cinemaTasks
                    .findIndex(task => task.taskId === action.payload.taskId);

                if (existingTaskIndex === -1) {
                    // Add new task
                    set(taskProgressAtom, {
                        ...currentProgress,
                        cinemaTasks: [
                            ...currentProgress.cinemaTasks,
                            {
                                taskId: action.payload.taskId,
                                rewardClaimed: false,
                                rewardCoins: action.payload.progress.rewardCoins || 0,
                                createdAt: action.payload.progress.createdAt || Date.now(),
                                taskItems: action.payload.progress.taskItems || [],
                                ...action.payload.progress
                            }
                        ]
                    });
                } else {
                    // Update existing task
                    const updatedTasks = [...currentProgress.cinemaTasks];
                    updatedTasks[existingTaskIndex] = {
                        ...updatedTasks[existingTaskIndex],
                        ...action.payload.progress,
                        taskItems: action.payload.progress.taskItems ||
                            updatedTasks[existingTaskIndex].taskItems
                    };

                    set(taskProgressAtom, {
                        ...currentProgress,
                        cinemaTasks: updatedTasks
                    });
                }
                break;
            }
            case 'special': {
                const existingTaskIndex = currentProgress.specialTasks
                    .findIndex(task => task.taskId === action.payload.taskId);

                if (existingTaskIndex === -1) {
                    // Add new task
                    set(taskProgressAtom, {
                        ...currentProgress,
                        specialTasks: [
                            ...currentProgress.specialTasks,
                            {
                                taskId: action.payload.taskId,
                                rewardClaimed: false,
                                rewardCoins: action.payload.progress.rewardCoins || 0,
                                createdAt: action.payload.progress.createdAt || Date.now(),
                                taskItems: action.payload.progress.taskItems || [],
                                ...action.payload.progress
                            }
                        ]
                    });
                } else {
                    // Update existing task
                    const updatedTasks = [...currentProgress.specialTasks];
                    updatedTasks[existingTaskIndex] = {
                        ...updatedTasks[existingTaskIndex],
                        ...action.payload.progress,
                        taskItems: action.payload.progress.taskItems ||
                            updatedTasks[existingTaskIndex].taskItems
                    };

                    set(taskProgressAtom, {
                        ...currentProgress,
                        specialTasks: updatedTasks
                    });
                }
                break;
            }
            case 'league': {
                const existingTaskIndex = currentProgress.leagueTasks
                    .findIndex(task => task.taskId === action.payload.taskId);

                if (existingTaskIndex === -1) {
                    // Add new task
                    set(taskProgressAtom, {
                        ...currentProgress,
                        leagueTasks: [
                            ...currentProgress.leagueTasks,
                            {
                                taskId: action.payload.taskId,
                                rewardClaimed: false,
                                rewardCoins: action.payload.progress.rewardCoins || 0,
                                completedAt: action.payload.progress.completedAt,
                                createdAt: action.payload.progress.createdAt || Date.now(),
                            }
                        ]
                    });
                } else {
                    // Update existing task
                    const updatedTasks = [...currentProgress.leagueTasks];
                    updatedTasks[existingTaskIndex] = {
                        ...updatedTasks[existingTaskIndex],
                        ...action.payload.progress,
                    };

                    set(taskProgressAtom, {
                        ...currentProgress,
                        leagueTasks: updatedTasks
                    });
                }
                break;
            }
            case 'referral': {
                const existingTaskIndex = currentProgress.referralTasks
                    .findIndex(task => task.taskId === action.payload.taskId);

                if (existingTaskIndex === -1) {
                    // Add new task
                    set(taskProgressAtom, {
                        ...currentProgress,
                        referralTasks: [
                            ...currentProgress.referralTasks,
                            {
                                taskId: action.payload.taskId,
                                rewardClaimed: false,
                                rewardCoins: action.payload.progress.rewardCoins || 0,
                                completedAt: action.payload.progress.completedAt,
                                createdAt: action.payload.progress.createdAt || Date.now(),
                            }
                        ]
                    });
                } else {
                    // Update existing task
                    const updatedTasks = [...currentProgress.referralTasks];
                    updatedTasks[existingTaskIndex] = {
                        ...updatedTasks[existingTaskIndex],
                        ...action.payload.progress,
                    };

                    set(taskProgressAtom, {
                        ...currentProgress,
                        referralTasks: updatedTasks
                    });
                }
                break;
            }
        }
    }
);

// Cinema task API action atom
export const startCinemaTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/cinema/${taskId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersCinemaTaskResponse;
        const taskData = data.tasks.cinemaTasks[0];

        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'cinema',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        return data;
    }
);

// Cinema task item API action atom
export const completeCinemaTaskItemAtom = atom(
    null,
    async (get, set, params: { taskId: string, itemId: string, answer?: string }) => {
        const accessToken = get(accessTokenAtom);

        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/cinema/${params.taskId}/${params.itemId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                answer: params.answer
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersCinemaTaskItemResponse;
        const taskData = data.tasks.cinemaTasks[0];

        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'cinema',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        return data;
    }
);

// Cinema task claim API action atom
export const claimCinemaTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);

        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/cinema/${taskId}/claim`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersCinemaTaskClaimResponse;
        const taskData = data.tasks.cinemaTasks[0];

        // Update task progress
        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'cinema',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        // Update local coin calculation
        set(localClickerStateAtom, prev => {
            const newEarnedCoins = {
                ...prev.localCoinCalculation.earnedCoins,
                cinemaTaskRewards: prev.localCoinCalculation.earnedCoins.cinemaTaskRewards + taskData.rewardCoins,
                totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + taskData.rewardCoins
            };

            return {
                ...prev,
                localCoinCalculation: {
                    ...prev.localCoinCalculation,
                    earnedCoins: newEarnedCoins,
                    totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                }
            };
        });

        // Update global coin state
        set(coinsAtom, (prev: CoinsState) => {
            const newEarnedCoins = {
                ...prev.calculation.earnedCoins,
                cinemaTaskRewards: prev.calculation.earnedCoins.cinemaTaskRewards + taskData.rewardCoins,
                totalEarned: prev.calculation.earnedCoins.totalEarned + taskData.rewardCoins
            };

            return {
                ...prev,
                calculation: {
                    ...prev.calculation,
                    earnedCoins: newEarnedCoins,
                    totalCoins: newEarnedCoins.totalEarned - prev.calculation.spentCoins.totalSpent
                }
            };
        });

        return data;
    }
);

// Special task API action atom
export const startSpecialTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/special/${taskId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersSpecialTaskResponse;
        const taskData = data.tasks.specialTasks[0];

        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'special',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        return data;
    }
);

// Special task item API action atom
export const completeSpecialTaskItemAtom = atom(
    null,
    async (get, set, params: { taskId: string, itemId: string, answer?: string }) => {
        const accessToken = get(accessTokenAtom);

        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/special/${params.taskId}/${params.itemId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                answer: params.answer
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersSpecialTaskItemResponse;
        const taskData = data.tasks.specialTasks[0];

        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'special',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        return data;
    }
);

// Special task claim API action atom
export const claimSpecialTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/special/${taskId}/claim`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data = await response.json() as PostUsersSpecialTaskClaimResponse;
        const taskData = data.tasks.specialTasks[0];

        // Update task progress
        set(updateTaskProgressAtom, {
            type: 'UPDATE_TASK_PROGRESS',
            payload: {
                taskType: 'special',
                taskId: taskData.taskId,
                progress: {
                    rewardClaimed: taskData.rewardClaimed,
                    rewardCoins: taskData.rewardCoins,
                    completedAt: taskData.completedAt,
                    createdAt: taskData.createdAt,
                    taskItems: taskData.taskItems
                }
            }
        });

        // Update local coin calculation
        set(localClickerStateAtom, prev => {
            const newEarnedCoins = {
                ...prev.localCoinCalculation.earnedCoins,
                specialTaskRewards: prev.localCoinCalculation.earnedCoins.specialTaskRewards + taskData.rewardCoins,
                totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + taskData.rewardCoins
            };

            return {
                ...prev,
                localCoinCalculation: {
                    ...prev.localCoinCalculation,
                    earnedCoins: newEarnedCoins,
                    totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                }
            };
        });

        // Update global coin state
        set(coinsAtom, (prev: CoinsState) => {
            const newEarnedCoins = {
                ...prev.calculation.earnedCoins,
                specialTaskRewards: prev.calculation.earnedCoins.specialTaskRewards + taskData.rewardCoins,
                totalEarned: prev.calculation.earnedCoins.totalEarned + taskData.rewardCoins
            };

            return {
                ...prev,
                calculation: {
                    ...prev.calculation,
                    earnedCoins: newEarnedCoins,
                    totalCoins: newEarnedCoins.totalEarned - prev.calculation.spentCoins.totalSpent
                }
            };
        });

        return data;
    }
);

// League task claim API action atom
export const claimLeagueTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);

        try {
            const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/league/${taskId}/claim`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            }

            const data = await response.json() as PostUsersLeagueTaskClaimResponse;
            const taskData = data.tasks.leagueTasks[0];

            // Update task progress using updateTaskProgressAtom
            set(updateTaskProgressAtom, {
                type: 'UPDATE_TASK_PROGRESS',
                payload: {
                    taskType: 'league',
                    taskId: taskData.taskId,
                    progress: {
                        rewardClaimed: taskData.rewardClaimed,
                        rewardCoins: taskData.rewardCoins,
                        completedAt: taskData.completedAt,
                        createdAt: taskData.createdAt
                    }
                }
            });

            // Update local coin calculation
            set(localClickerStateAtom, prev => {
                const newEarnedCoins = {
                    ...prev.localCoinCalculation.earnedCoins,
                    leagueTaskRewards: prev.localCoinCalculation.earnedCoins.leagueTaskRewards + taskData.rewardCoins,
                    totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + taskData.rewardCoins
                };

                return {
                    ...prev,
                    localCoinCalculation: {
                        ...prev.localCoinCalculation,
                        earnedCoins: newEarnedCoins,
                        totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                    }
                };
            });

            // Update global coin state
            set(coinsAtom, (prev: CoinsState) => {
                const newEarnedCoins = {
                    ...prev.calculation.earnedCoins,
                    leagueTaskRewards: prev.calculation.earnedCoins.leagueTaskRewards + taskData.rewardCoins,
                    totalEarned: prev.calculation.earnedCoins.totalEarned + taskData.rewardCoins
                };

                return {
                    ...prev,
                    calculation: {
                        ...prev.calculation,
                        earnedCoins: newEarnedCoins,
                        totalCoins: newEarnedCoins.totalEarned - prev.calculation.spentCoins.totalSpent
                    }
                };
            });

            return data;
        } catch (error) {
            console.error('Failed to claim league task:', error);
            throw error;
        }
    }
);

// Referral task claim API action atom
export const claimReferralTaskAtom = atom(
    null,
    async (get, set, taskId: string) => {
        const accessToken = get(accessTokenAtom);
        try {
            const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/tasks/ref/${taskId}/claim`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            }
            const data = await response.json() as PostUsersReferralTaskClaimResponse;
            const taskData = data.tasks.referralTasks[0];

            // Update task progress using updateTaskProgressAtom
            set(updateTaskProgressAtom, {
                type: 'UPDATE_TASK_PROGRESS',
                payload: {
                    taskType: 'referral',
                    taskId: taskData.taskId,
                    progress: {
                        rewardClaimed: taskData.rewardClaimed,
                        rewardCoins: taskData.rewardCoins,
                        completedAt: taskData.completedAt,
                        createdAt: taskData.createdAt
                    }
                }
            });

            // Update local coin calculation
            set(localClickerStateAtom, prev => {
                const newEarnedCoins = {
                    ...prev.localCoinCalculation.earnedCoins,
                    referralTaskRewards: prev.localCoinCalculation.earnedCoins.referralTaskRewards + taskData.rewardCoins,
                    totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + taskData.rewardCoins
                };

                return {
                    ...prev,
                    localCoinCalculation: {
                        ...prev.localCoinCalculation,
                        earnedCoins: newEarnedCoins,
                        totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                    }
                };
            });

            // Update global coin state
            set(coinsAtom, (prev: CoinsState) => {
                const newEarnedCoins = {
                    ...prev.calculation.earnedCoins,
                    referralTaskRewards: prev.calculation.earnedCoins.referralTaskRewards + taskData.rewardCoins,
                    totalEarned: prev.calculation.earnedCoins.totalEarned + taskData.rewardCoins
                };

                return {
                    ...prev,
                    calculation: {
                        ...prev.calculation,
                        earnedCoins: newEarnedCoins,
                        totalCoins: newEarnedCoins.totalEarned - prev.calculation.spentCoins.totalSpent
                    }
                };
            });

            return data;
        } catch (error) {
            console.error('Failed to claim referral task:', error);
            throw error;
        }
    }
);

// atomsに追加
export const dailyCheckinStateAtom = atom<DailyCheckinState>({
    offers: [],
    completed: undefined
});

// グローバルアナウンスの表示状態を管理するatom
export const globalAnnouncementsShownAtom = atomWithStorage<string[]>(
    'global-announcements-shown',
    [],
    typeof window !== "undefined"
        ? createJSONStorage(() => window.sessionStorage)
        : undefined,
    { getOnInit: true }
);

// チェックイン実行用のaction atom
export const performDailyCheckinAtom = atom(
    null,
    async (get, set, params: { itemId: string, transactionId?: string }) => {
        const accessToken = get(accessTokenAtom);
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/checkin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(params)
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        const data: PostUsersDailyCheckinResponse = await response.json();

        // デイリーチェックイン状態の更新
        set(dailyCheckinStateAtom, data.data.dailyCheckin);

        return data;
    }
);

// メトリクス情報を保持するatomを追加
export const metricsAtom = atom<DailyMetricsSummary | null>(null);

// Add new types for TON transactions
interface TonTransaction {
    transactionId: string;
    tonDestinationWalletAddress: string;
    price: number;
    createdAt: number;
}

interface TonVerification {
    verified: boolean;
    transactionId: string;
    verifiedAt: number;
    tonTransactionHash?: string;
    tonFromWalletAddress?: string;
    error?: {
        code: string;
        message: string;
    };
}

// Add new atoms for TON payment state management
export const tonTransactionAtom = atom<TonTransaction | null>(null);
export const tonVerificationAtom = atom<TonVerification | null>(null);
export const tonPaymentLoadingAtom = atom<boolean>(false);
export const tonVerificationPollingAtom = atom<NodeJS.Timeout | null>(null);

// Add new action atoms for TON payment flow
export const createTonTransactionAtom = atom(null, async (get, set, params: {
    userId: string;
    itemType: string;
    priceId: string;
}) => {
    set(tonPaymentLoadingAtom, true);
    const accessToken = get(accessTokenAtom);
    try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/payment/ton/transactions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                item: {
                    itemType: params.itemType,
                    priceId: params.priceId
                }
            })
        });

        if (!response.ok) throw new Error('Failed to create TON transaction');
        const data = await response.json();
        set(tonTransactionAtom, data.data.transaction);
        return data.data.transaction;
    } catch (error) {
        console.error('Failed to create TON transaction:', error);
        throw error;
    } finally {
        set(tonPaymentLoadingAtom, false);
    }
});

export const verifyTonTransactionAtom = atom(null, async (_, set, transactionId: string) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/payment/ton/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ transactionId })
        });

        if (!response.ok) throw new Error('Failed to verify TON transaction');
        const data = await response.json();
        set(tonVerificationAtom, data.data);
        return data.data;
    } catch (error) {
        console.error('Failed to verify TON transaction:', error);
        throw error;
    }
});

// Shop items atom
export const shopItemsAtom = atom<GetShopItemsResponse['items']>([]);

// Shop items purchase action atom
export const purchaseShopItemAtom = atom(null, async (get, set, params: {
    itemId: string;
    priceId: string;
    amount?: number;
    inputs: { inputId: string; value: string; }[];
}) => {
    const accessToken = get(accessTokenAtom);
    try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/shops/purchase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(params)
        });

        if (!response.ok) {
            // まずテキストとして読み取る
            const responseText = await response.text();

            try {
                // JSONとしてパースを試みる
                const error = JSON.parse(responseText);
                throw new Error(error.message || 'Purchase failed');
            } catch {
                // JSONパースに失敗した場合、テキストそのものをエラーとして使用
                throw new Error(responseText || 'Purchase failed');
            }
        }

        const data = await response.json();

        // Update coins
        if (data.coins) {
            const totalEarned = data.coins.earnedCoins.clickEarned +
                data.coins.earnedCoins.botTotalEarned +
                data.coins.earnedCoins.cinemaTaskRewards +
                data.coins.earnedCoins.specialTaskRewards +
                data.coins.earnedCoins.leagueTaskRewards +
                data.coins.earnedCoins.referralTaskRewards +
                data.coins.earnedCoins.dailyCheckinRewards +
                data.coins.earnedCoins.operationalCoins +
                data.coins.earnedCoins.itemBonusCoins;

            set(coinsAtom, {
                clickEarnedCoins: data.coins.earnedCoins.clickEarned,
                botEarnedCoins: data.coins.earnedCoins.botTotalEarned,
                calculation: {
                    earnedCoins: {
                        ...data.coins.earnedCoins,
                        totalEarned,
                    },
                    spentCoins: data.coins.spentCoins,
                    totalCoins: totalEarned - data.coins.spentCoins.totalSpent
                }
            });

            set(localClickerStateAtom, prev => ({
                ...prev,
                localCoinCalculation: {
                    earnedCoins: data.coins.earnedCoins,
                    spentCoins: data.coins.spentCoins,
                    totalCoins: totalEarned - data.coins.spentCoins.totalSpent
                }
            }));
        }

        // Update shop items remains
        if (params.amount) {
            set(shopItemsAtom, prevItems =>
                prevItems.map(item =>
                    item.itemId === params.itemId && item.remains !== null
                        ? { ...item, remains: item.remains - params.amount! }
                        : item
                )
            );
        }

        // Update boost if available
        if (data.boost) {
            set(boostAtom, data.boost);
        }

        // Update item histories if available
        if (data.itemHistories) {
            set(itemHistoriesAtom, data.itemHistories);
        }

        return data;
    } catch (error) {
        console.error('Failed to purchase shop item:', error);
        throw error;
    }
});

// Shop items fetch action atom
export const fetchShopItemsAtom = atom(null, async (get, set) => {
    const accessToken = get(accessTokenAtom);
    try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/shops/get_items`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) throw new Error('Failed to fetch shop items');
        const data = await response.json() as GetShopItemsResponse;
        set(shopItemsAtom, data.items);
        return data.items;
    } catch (error) {
        console.error('Failed to fetch shop items:', error);
        throw error;
    }
});

// Global actions atom
export const globalActionsAtom = atom(null, async (get, set, action: {
    type: 'INIT' | 'CLICK' | 'UPDATE_COINS';
    payload: PostUsersChallenge | ClickRequest | UpdateDailyCheckinReward;
}) => {
    set(errorAtom, null);
    set(clickerErrorAtom, null);
    try {
        switch (action.type) {
            case 'INIT': {
                set(isInitLoadingAtom, true);
                const payload = action.payload as PostUsersChallenge;
                const [userInitDataResponse, masterDataResponse] = await Promise.all([
                    fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/challenge`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(payload)
                    }),
                    fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/master`, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    })
                ]);

                if (!userInitDataResponse.ok || !masterDataResponse.ok) {
                    throw new Error('API request failed');
                }

                const initData = await userInitDataResponse.json() as GlobalContextResponse;
                const masterData = await masterDataResponse.json() as MasterContextResponse;

                // 全atomの更新
                set(accessTokenAtom, initData.accessToken);
                set(masterDataAtom, {
                    ...masterData.masterData,
                    globalAnnouncements: masterData.masterData.globalAnnouncements || []
                });

                set(userBaseAtom, {
                    telegramId: initData.player.telegramId,
                    username: initData.player.username,
                    firstName: initData.player.firstName,
                    lastName: initData.player.lastName,
                    languageCode: initData.player.languageCode,
                });

                set(userStatusAtom, {
                    isPremium: initData.player.isPremium,
                    isBanned: initData.player.isBanned,
                    banReason: initData.player.banReason,
                    bannedAt: initData.player.bannedAt,
                });

                set(userTimestampsAtom, {
                    createdAt: initData.player.createdAt,
                    lastLoginAt: initData.player.lastLoginAt,
                    lastActiveAt: initData.player.lastActiveAt,
                });
                set(referralsAtom, initData.player.referrals);
                set(levelsAtom, initData.player.levels);

                set(tapBotStateAtom, initData.player.tapBotState);

                // サーバーステートの更新
                set(coinsAtom, {
                    clickEarnedCoins: initData.player.coins.clickEarnedCoins,
                    botEarnedCoins: initData.player.coins.botEarnedCoins,
                    calculation: initData.player.coins.calculation
                });

                // エナジー関連の初期化を更新
                const currentMaxEnergy = masterData.masterData.energyLimitLevels.find(
                    level => level.level === initData.player.levels.energyLimitLevel
                )?.energyLimit ?? 0;

                const currentRechargeSpeed = masterData.masterData.rechargeSpeedLevels.find(
                    level => level.level === initData.player.levels.rechargeSpeedLevel
                )?.rechargeCoinPerSec ?? 0;

                // クリッカー情報の更新（エナジーは初期値のみ使用）
                set(clickerAtom, {
                    clicks: initData.player.clicker.clicks,
                    energy: initData.player.clicker.energy, // 初期値としてのみ使用
                    maxEnergy: currentMaxEnergy,
                    rechargeSpeed: currentRechargeSpeed,
                    freeBoostDosageLimit: initData.player.clicker.freeBoostDosageLimit,
                    freeRechargeLimit: initData.player.clicker.freeRechargeLimit,
                    lastSyncTime: Date.now(), // 現在時刻を使用
                });

                // ローカルステートの初期化（エナジー値を含む）
                set(localClickerStateAtom, {
                    isInitialized: true,
                    pendingClicks: 0,
                    lastClickTime: Date.now(),
                    localEarnedCoins: initData.player.coins.clickEarnedCoins,
                    localEnergy: initData.player.clicker.energy, // 初期値として設定
                    lastSyncTime: Date.now(),
                    lastEnergySync: Date.now(), // エナジー計算用のタイムスタンプ
                    localCoinCalculation: initData.player.coins.calculation
                });

                // boostsの設定
                set(boostAtom, initData.player.boost);

                // itemHistoriesの設定
                set(itemHistoriesAtom, initData.player.itemHistories);

                // タスク進捗の更新
                set(taskProgressAtom, {
                    cinemaTasks: initData.player.tasks.cinemaTasks,
                    specialTasks: initData.player.tasks.specialTasks,
                    leagueTasks: initData.player.tasks.leagueTasks,
                    referralTasks: initData.player.tasks.referralTasks,
                });

                // デイリーチェックイン状態の更新を追加
                set(dailyCheckinStateAtom, initData.player.dailyCheckin);

                // メトリクス情報の更新を追加
                if (masterData.metrics) {
                    set(metricsAtom, masterData.metrics);
                } else {
                    set(metricsAtom, null);
                }

                // Pending paymentsの確認
                const STORAGE_KEY = 'pending_payments';
                try {
                    const stored = localStorage.getItem(STORAGE_KEY);
                    if (stored) {
                        const payments = JSON.parse(stored);
                        if (Array.isArray(payments) && payments.length > 0) {
                            // ここでpending paymentsが存在することを確認
                            console.log('Found pending payments:', payments);
                        }
                    }
                } catch (error) {
                    console.error('Error checking pending payments:', error);
                }
                break;
            }
            case 'CLICK': {
                const payload = action.payload as ClickRequest;
                const accessToken = get(accessTokenAtom);

                // 新しいResponseを毎回作成
                const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/click`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        clicks: payload.clicks,
                        clientRequestedTime: payload.clientRequestedTime
                    })
                });

                // レスポンスをすぐにJSONとしてパース
                const clickData = await response.json();

                if (!response.ok) {
                    throw new GameError(clickData.message, clickData.code, response.status);
                }

                // サーバーのレスポンスに基づいてローカルステートを更新

                /*
                set(localClickerStateAtom, prev => {
                    const newEarnedCoins = {
                        ...prev.localCoinCalculation.earnedCoins,
                        clickEarned: clickData.summary.currentEarnedCoins,
                        botTotalEarned: clickData.player.tapBotState.botEarnedCoins,
                        totalEarned: (
                            clickData.summary.currentEarnedCoins +
                            clickData.player.tapBotState.botEarnedCoins +
                            prev.localCoinCalculation.earnedCoins.cinemaTaskRewards +
                            prev.localCoinCalculation.earnedCoins.specialTaskRewards +
                            prev.localCoinCalculation.earnedCoins.leagueTaskRewards +
                            prev.localCoinCalculation.earnedCoins.referralTaskRewards +
                            prev.localCoinCalculation.earnedCoins.dailyCheckinRewards
                        )
                    }; return {
                        ...prev,
                        pendingClicks: 0,
                        localEarnedCoins: clickData.summary.currentEarnedCoins,
                        localCoinCalculation: {
                            ...prev.localCoinCalculation,
                            earnedCoins: newEarnedCoins,
                            totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                        },
                        lastSyncTime: Date.now()
                    };
                });
                */

                // グローバルステートの更新
                set(clickerAtom, prev => ({
                    ...prev,
                    clicks: clickData.player.clicker.clicks,
                    lastSyncTime: Date.now(),
                }));

                set(coinsAtom, prev => ({
                    ...prev,
                    clickEarnedCoins: clickData.player.coins.clickEarnedCoins
                }));

                set(levelsAtom, clickData.player.levels);

                if (clickData.player.boost) {
                    set(boostAtom, clickData.player.boost);
                }

                if (clickData.player.tapBotState) {
                    set(tapBotStateAtom, clickData.player.tapBotState);
                }

                break;
            }

            case 'UPDATE_COINS': {
                const payload = action.payload as UpdateDailyCheckinReward;
                set(coinsAtom, (prev: CoinsState) => ({
                    ...prev,
                    calculation: {
                        ...prev.calculation,
                        earnedCoins: {
                            ...prev.calculation.earnedCoins,
                            dailyCheckinRewards: prev.calculation.earnedCoins.dailyCheckinRewards + (payload.earnedCoins.dailyCheckinRewards || 0),
                            totalEarned: prev.calculation.earnedCoins.totalEarned + (payload.earnedCoins.dailyCheckinRewards || 0)
                        },
                        totalCoins: (prev.calculation.earnedCoins.totalEarned + (payload.earnedCoins.dailyCheckinRewards || 0)) - prev.calculation.spentCoins.totalSpent
                    }
                }));

                // ローカルステートの完全な更新
                set(localClickerStateAtom, prev => ({
                    ...prev,
                    localCoinCalculation: {
                        ...prev.localCoinCalculation,
                        earnedCoins: {
                            ...prev.localCoinCalculation.earnedCoins,
                            dailyCheckinRewards: prev.localCoinCalculation.earnedCoins.dailyCheckinRewards + (payload.earnedCoins.dailyCheckinRewards || 0),
                            totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + (payload.earnedCoins.dailyCheckinRewards || 0)
                        },
                        totalCoins: (prev.localCoinCalculation.earnedCoins.totalEarned + (payload.earnedCoins.dailyCheckinRewards || 0)) - prev.localCoinCalculation.spentCoins.totalSpent
                    }
                }));

                break;
            }
        }

    } catch (error) {
        set(errorAtom, error instanceof Error ? error.message : 'Unknown error occurred');
        throw error;
    } finally {
        set(isInitLoadingAtom, false);
    }
});

// Custom hooks
export const useGlobalActions = () => {
    const [, dispatch] = useAtom(globalActionsAtom);

    return {
        initializeApp: async () => {
            if (!initData || !initData.user()) return;
            const { initDataRaw } = retrieveLaunchParams();
            const payload: PostUsersChallenge = {
                initDataRaw: initDataRaw!,
                user: {
                    id: initData.user()!.id,
                    first_name: initData.user()?.firstName,
                    last_name: initData.user()?.lastName,
                    username: initData.user()?.username,
                    language_code: initData.user()?.languageCode,
                    is_premium: initData.user()?.isPremium
                },
                auth_date: Number(initData.authDate()),
                hash: initData.hash()!
            };
            await dispatch({ type: 'INIT', payload });
        },

        sendClicks: async (clicks: number) => {
            await dispatch({
                type: 'CLICK',
                payload: {
                    clicks,
                    clientRequestedTime: Date.now()
                }
            });
        },
    };
};