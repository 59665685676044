import React, { useState, useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';
import { accessTokenAtom } from '../../../../app/atoms';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { backButton } from '@telegram-apps/sdk-react';
import { Copy } from 'lucide-react';
import { useToast } from '../../../../components/toast/useToast';

// Purchase history item type definition
interface ShopItemHistoryItem {
    transactionId: string;
    shopItemName: string;
    shopItemIconUrl: string | null;
    unitPrice: number;
    amount: number;
    totalPrice: number;
    paymentStatus: 'completed' | 'refunded';
    createdAt: number;
    inputs: {
        title: string;
        value: string;
    }[];
}

// Pagination info type definition
interface PaginationInfo {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

// Coin calculation type definition
interface CoinCalculation {
    earnedCoins: {
        clickEarned: number;
        botTotalEarned: number;
        cinemaTaskRewards: number;
        specialTaskRewards: number;
        leagueTaskRewards: number;
        referralTaskRewards: number;
        dailyCheckinRewards: number;
        operationalCoins: number;
        itemBonusCoins: number;
        totalEarned: number;
    };
    spentCoins: {
        tapBotUpgrades: number;
        tapLevelUpgrades: number;
        energyLimitUpgrades: number;
        rechargeSpeedUpgrades: number;
        boostItemPurchases: number;
        dailyCheckinPurchases: number;
        fullChargePurchases: number;
        shopItemPurchases: number;
        operationalCoinsDeduction: number;
        totalSpent: number;
    };
    totalCoins: number;
}

// Item history type definition
interface ItemHistory {
    transactionId: string;
    userId: string;
    itemId: string;
    priceId: string;
    price: number;
    currencyId: string;
    createdAt: number;
}

// Boost info type definition
interface BoostInfo {
    userId: string;
    effectiveUntil: number;
    effectiveAt: number;
    multiplier: number;
}

// API response type definition
interface ShopItemHistoryResponse {
    status: 'success' | 'error';
    message?: string;
    items: ShopItemHistoryItem[];
    pagination: PaginationInfo;
    coins: CoinCalculation;
    itemHistories: {
        fullChargeHistories: ItemHistory[];
        boostHistories: ItemHistory[];
    };
    boost: BoostInfo;
}

// Purchase history item card component
const PurchaseHistoryCard: React.FC<{ item: ShopItemHistoryItem }> = ({ item }) => {
    const formattedDate = format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm');
    const statusColor = item.paymentStatus === 'completed' ? 'text-white' : 'text-red-600';
    const statusBgColor = item.paymentStatus === 'completed' ? 'bg-[#DE6437]' : 'bg-red-100';
    const { showToast } = useToast();

    const handleCopyTransactionId = async () => {
        try {
            await navigator.clipboard.writeText(item.transactionId);
            showToast(
                "Success",
                "success",
                "Transaction ID copied to clipboard!"
            );
        } catch (err) {
            console.error(err);
            showToast(
                "Error",
                "error",
                "Failed to copy transaction ID"
            );
        }
    };

    return (
        <div className="bg-white rounded-lg border border-[#E6E6E6] p-4 mb-4">
            {/* Header with item name and status */}
            <div className="flex justify-between items-start mb-3">
                <div className="flex items-center">
                    {item.shopItemIconUrl && (
                        <div className="flex-shrink-0 h-10 w-10 mr-3">
                            <img className="h-10 w-10 rounded-full" src={item.shopItemIconUrl} alt={item.shopItemName} />
                        </div>
                    )}
                    <div>
                        <h3 className="text-md font-regular text-[#1A1A1A]">{item.shopItemName}</h3>
                    </div>
                </div>
                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-regular rounded-full ${statusColor} ${statusBgColor}`}>
                    {item.paymentStatus === 'completed' ? 'Completed' : 'Refunded'}
                </span>
            </div>

            {/* Transaction ID button - full width */}
            <button
                onClick={handleCopyTransactionId}
                className="w-full mb-3 px-4 py-2 rounded-lg border border-[#1A1A1A] bg-transparent transition-colors duration-200 flex items-start gap-2 hover:border-[#F9825D]"
                aria-label="Copy transaction ID"
            >
                <Copy className="w-4 h-4 text-[#1A1A1A] mt-0.5" />
                <div className="flex flex-col items-start">
                    <span className="text-xs font-regular text-[#1A1A1A]">Transaction ID:</span>
                    <span className="text-xs font-regular text-[#1A1A1A]">{item.transactionId}</span>
                </div>
            </button>

            {/* Purchase details */}
            <div className="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <p className="text-xs text-[#666666]">Price</p>
                    <p className="text-sm font-regular text-[#1A1A1A]">{item.unitPrice.toLocaleString()} Coins</p>
                </div>
                <div>
                    <p className="text-xs text-[#666666]">Quantity</p>
                    <p className="text-sm font-regular text-[#1A1A1A]">{item.amount}</p>
                </div>
                <div>
                    <p className="text-xs text-[#666666]">Total</p>
                    <p className="text-sm font-regular text-[#1A1A1A]">{item.totalPrice.toLocaleString()} Coins</p>
                </div>
                <div>
                    <p className="text-xs text-[#666666]">Date</p>
                    <p className="text-sm font-regular text-[#1A1A1A]">{formattedDate}</p>
                </div>
            </div>

            {/* Input information if available */}
            {item.inputs && item.inputs.length > 0 && (
                <div className="mt-3 pt-3 border-t border-[#E6E6E6]">
                    <p className="text-xs text-[#666666] mb-1">Input Information</p>
                    <div className="space-y-1">
                        {item.inputs.map((param, idx) => (
                            <div key={idx} className="text-sm">
                                <span className="font-regular text-[#1A1A1A]">{param.title}:</span> {param.value}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

// Purchase history list component
const ShopItemPurchaseHistoryList: React.FC = () => {
    const [accessToken] = useAtom(accessTokenAtom);
    const [purchaseHistory, setPurchaseHistory] = useState<ShopItemHistoryItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pagination, setPagination] = useState<PaginationInfo>({
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        hasNextPage: false,
        hasPreviousPage: false
    });

    // Function to fetch data from API wrapped in useCallback
    const fetchPurchaseHistory = useCallback(async (page = 1, limit = 10) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(
                `${import.meta.env.VITE_API_BASE_URL}/api/v1/users/shops/purchase/history?page=${page}&limit=${limit}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch purchase history');
            }

            const data = await response.json() as ShopItemHistoryResponse;
            setPurchaseHistory(data.items);
            setPagination(data.pagination);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred while fetching purchase history');
            console.error('Failed to fetch purchase history:', err);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    // Fetch data on initial render if access token is available
    useEffect(() => {
        if (accessToken) {
            fetchPurchaseHistory(pagination.currentPage);
        }
    }, [accessToken, fetchPurchaseHistory, pagination.currentPage]);

    // Handle page change
    const handlePageChange = (newPage: number) => {
        fetchPurchaseHistory(newPage);
    };

    return (
        <div className="w-full">
            {loading && (
                <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#1A1A1A]"></div>
                </div>
            )}

            {error && (
                <div className="bg-red-100 border border-[#E6E6E6] text-red-700 px-4 py-3 rounded mb-4">
                    <p>{error}</p>
                </div>
            )}

            {pagination.totalItems > 0 && (
                <div className="px-4 py-3 text-sm text-[#666666]">
                    Showing {purchaseHistory.length} of {pagination.totalItems} items
                </div>
            )}

            <div className="px-4">
                {purchaseHistory.length > 0 ? (
                    purchaseHistory.map((item) => (
                        <PurchaseHistoryCard key={item.transactionId} item={item} />
                    ))
                ) : (
                    <div className="py-8 text-center text-sm text-[#666666]">
                        {loading ? 'Loading data...' : 'No purchase history available'}
                    </div>
                )}
            </div>

            {pagination.totalPages > 1 && (
                <div className="mt-4 flex justify-center pb-4">
                    <nav className="relative z-0 inline-flex rounded-md -space-x-px" aria-label="Pagination">
                        <button
                            onClick={() => handlePageChange(pagination.currentPage - 1)}
                            disabled={!pagination.hasPreviousPage}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-[#1A1A1A] bg-white text-sm font-regular text-[#1A1A1A] hover:bg-[#F8F7F7] disabled:bg-[#E6E6E6] disabled:cursor-not-allowed"
                        >
                            Previous
                        </button>
                        {Array.from({ length: pagination.totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`relative inline-flex items-center px-4 py-2 border border-[#1A1A1A] text-sm font-regular ${pagination.currentPage === page
                                    ? 'z-10 bg-[#F8F7F7] border-[#1A1A1A] text-[#1A1A1A]'
                                    : 'bg-white text-[#1A1A1A] hover:bg-[#F8F7F7]'
                                    }`}
                            >
                                {page}
                            </button>
                        ))}
                        <button
                            onClick={() => handlePageChange(pagination.currentPage + 1)}
                            disabled={!pagination.hasNextPage}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-[#1A1A1A] bg-white text-sm font-regular text-[#1A1A1A] hover:bg-[#F8F7F7] disabled:bg-[#E6E6E6] disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </nav>
                </div>
            )}
        </div>
    );
};

// Main component
export const ShopItemPurchaseHistoryRoute = () => {
    const navigate = useNavigate();

    // Add back button functionality
    useEffect(() => {
        backButton.show();
        backButton.onClick(() => {
            navigate(-1);
            backButton.hide();
        });
        return () => {
            backButton.hide();
        };
    }, [navigate]);

    return (
        <div className="flex flex-col h-screen bg-[#F8F7F7]">
            <div className="flex items-center justify-between px-4 py-4">
                <h1 className="text-xl font-regular text-[#1A1A1A]">Purchase History</h1>
            </div>
            <div className="flex-1 overflow-auto px-4"
                style={{
                    paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
                }}
            >
                <p className="text-sm text-[#666666] mb-4">View the history of items purchased from the shop</p>
                <div className="bg-white rounded-lg border border-[#E6E6E6]">
                    <ShopItemPurchaseHistoryList />
                </div>
            </div>
        </div>
    );
};

export default ShopItemPurchaseHistoryRoute;