import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import ShopItemConfirmationModal from './ShopItemConfirmationModal/ShopItemConfirmationModal';

import coinIcon from '../../../../assets/fujiyamatap-icons/ui/coin.webp';
import tonIcon from '../../../../assets/fujiyamatap-icons/ui/ton_symbol.svg';

const CURRENCY_ICONS: Record<string, string> = {
    'coin': coinIcon,
    'ton': tonIcon
};

interface Price {
    priceId: string;
    price: number;
    currencyId: string;
}

interface PremiseCondition {
    name: string;
    description: string;
    conditionType: string;
    conditionSubjectId: string;
    passed: boolean;
}

interface ShopItemOutlineButtonProps {
    title: string;
    iconUrl: string;
    description: string;
    canPurchase: boolean;
    canSelectMultiple: boolean;
    purchasing: boolean;
    itemId: string;
    price: Price;
    inputs: {
        inputId: string;
        typeId: string;
        title: string;
        description: string | null;
        inputType: string;
    }[];
    premiseConditions?: PremiseCondition[];
    stock?: number;
    remains?: number;
}

const ShopItemOutlineButton: React.FC<ShopItemOutlineButtonProps> = ({
    title,
    iconUrl,
    description,
    canPurchase,
    canSelectMultiple,
    purchasing,
    itemId,
    price,
    inputs,
    premiseConditions = [],
    stock,
    remains,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getDisabledState = () => {
        if (!canPurchase) return true;
        if (purchasing) return true;
        if (remains !== undefined && remains <= 0) return true;
        return false;
    };

    const getButtonStyle = () => {
        const baseStyle = "w-full border border-[#B3B3B3] rounded-lg p-4";
        const isButtonDisabled = getDisabledState();

        if (isButtonDisabled) {
            return `${baseStyle} opacity-50 cursor-not-allowed`;
        }
        return `${baseStyle} hover:bg-gray-50`;
    };

    const handleClick = () => {
        if (!getDisabledState()) {
            setIsModalOpen(true);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <button
                onClick={handleClick}
                disabled={getDisabledState()}
                className={getButtonStyle()}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <img src={iconUrl} alt={title} className="max-w-12 max-h-12 w-auto h-auto object-contain" />

                        <div>
                            <div className="flex items-center gap-2">
                                <span className="text-base font-light font-mplus1p text-[#1A1A1A]">
                                    {title}
                                </span>
                            </div>

                            <div className="flex items-center gap-2 mt-1">
                                {price.price > 0 && (
                                    <div className="flex items-center gap-1">
                                        <img
                                            src={CURRENCY_ICONS[price.currencyId]}
                                            alt=""
                                            className="w-4 h-4 object-contain"
                                        />
                                        <span className="text-sm text-gray-600 font-mplus1p">
                                            {price.price.toLocaleString()} {price.currencyId === 'ton' ? 'TON' : 'coins'}
                                        </span>
                                    </div>
                                )}
                                {remains !== undefined && stock !== undefined && (
                                    <div className="flex items-center gap-1">
                                        <span className="text-sm text-gray-600 font-mplus1p">
                                            {remains}/{stock}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <ChevronRight className="w-10 h-10 text-[#333333]" />
                </div>
            </button>

            <ShopItemConfirmationModal
                isOpen={isModalOpen}
                onClose={handleClose}
                icon={iconUrl}
                title={title}
                description={description}
                itemId={itemId}
                priceId={price.priceId}
                inputs={inputs}
                canSelectMultiple={canSelectMultiple}
                price={{
                    unitPrice: price.price,
                    currency: price.currencyId
                }}
                premiseConditions={premiseConditions}
                remains={remains}
            />
        </>
    );
};

export default ShopItemOutlineButton;