import { useAtom } from 'jotai';
import { referralsAtom } from '../../../../app/atoms';
import AccountTabs from '../../../../components/account/accountTabs';
import LegalLinks from '../../../../components/account/settings/ExternalLinkButton';
import { useToast } from '../../../../components/toast/useToast';
import { Copy } from 'lucide-react';

export const SettingsRoute = () => {
    const [referrals] = useAtom(referralsAtom);
    const { showToast } = useToast();

    const handleCopyReferralCode = async () => {
        if (referrals.referralCode) {
            try {
                await navigator.clipboard.writeText(referrals.referralCode);
                showToast(
                    "Success",
                    "success",
                    "Referral code copied to clipboard!"
                );
            } catch (err) {
                console.error(err);
                showToast(
                    "Error",
                    "error",
                    "Failed to copy referral code"
                );
            }
        }
    };

    return (
        <div className="container mx-auto px-4 py-6 overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <AccountTabs />
            <div className="mt-6 space-y-6">
                {referrals.referralCode && (
                    <div className="bg-white rounded-lg shadow-sm p-4 border">
                        <h3 className="text-lg font-medium text-gray-900 mb-2">Referral Code</h3>
                        <div className="flex items-center justify-between bg-gray-50 p-3 rounded-md">
                            <div className="font-mono text-gray-700 truncate">
                                {referrals.referralCode}
                            </div>
                            <button
                                onClick={handleCopyReferralCode}
                                className="px-4 py-2 rounded text-sm font-medium transition-all duration-200 flex items-center gap-2 bg-[#DE6437] hover:bg-[#C55832] text-white"
                                aria-label="Copy referral code"
                            >
                                <Copy className="w-4 h-4" />
                                Copy
                            </button>
                        </div>
                    </div>
                )}

                <div className="max-w-2xl">
                    <LegalLinks />
                </div>
            </div>
        </div>
    );
};

export default SettingsRoute;