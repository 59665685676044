import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import {
    coinsAtom,
    localClickerStateAtom,
    currentLeagueAtom,
    shopItemsAtom,
    fetchShopItemsAtom,
    accessTokenAtom
} from '../../atoms';

import GameHeader from '../../../components/GameHeader/GameHeader';
import ShopItemOutlineButton from './ShopItemOutlineButton/ShopItemOutlineButton';
import { OutlineButton } from '../../../components/ui/outline-button/OutlineButton';
import { paths } from '../../../config/paths';
import energyIcon from '../../../assets/fujiyamatap-icons/ui/energy.webp';
export const GameRoute = () => {
    const navigate = useNavigate();
    const [localState] = useAtom(localClickerStateAtom);
    const [serverCoins] = useAtom(coinsAtom);
    const [currentLeague] = useAtom(currentLeagueAtom);
    const [shopItems] = useAtom(shopItemsAtom);
    const [, fetchShopItems] = useAtom(fetchShopItemsAtom);

    // ショップアイテムの取得(accessTokenが存在する場合のみ実行)
    const [accessToken] = useAtom(accessTokenAtom);
    useEffect(() => {
        if (accessToken) {
            fetchShopItems().catch(console.error);
        }
    }, [fetchShopItems, accessToken]);

    // 表示用の値を計算
    const displayValues = {
        totalCoins: localState.isInitialized
            ? localState.localCoinCalculation.totalCoins
            : serverCoins.calculation.totalCoins,
    };

    // 購入可能判定
    const canPurchaseItem = (price: number, currencyId: string = 'coin', remains?: number | null) => {
        // 在庫切れの場合は購入不可
        if (remains !== undefined && remains !== null && remains <= 0) return false;
        // 通貨による購入可否判定
        const res = currencyId === 'coin' ? displayValues.totalCoins >= price : true;
        return res || true;
    };

    return (
        <div className="flex flex-col h-screen bg-[#F8F7F7]">
            <GameHeader
                currentLeague={currentLeague}
                totalCoins={displayValues.totalCoins}
            />
            <div className="flex-1 overflow-auto px-4 pt-4">
                <div className="grid grid-cols-1 gap-4 pb-[calc(env(safe-area-inset-bottom)+8rem)]">
                    {/* Shop Items Header with History Button */}
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-regular text-[#1A1A1A]">Shop items</h2>
                        <OutlineButton
                            onClick={() => navigate(paths.game.shopItemPurchaseHistory.getHref())}
                            variant="dark"
                            icon={<img src={energyIcon} alt="Energy" className="h-5" />}
                        >
                            History
                        </OutlineButton>
                    </div>
                    {shopItems.map((item) => (
                        <ShopItemOutlineButton
                            key={item.itemId}
                            title={item.name}
                            iconUrl={item.iconUrl ?? ''}
                            description={item.description}
                            canPurchase={canPurchaseItem(item.price.price, item.price.currencyId, item.remains)}
                            purchasing={false}
                            canSelectMultiple={item.canSelectMultiple}
                            itemId={item.itemId}
                            price={item.price}
                            inputs={item.inputs || []}
                            premiseConditions={item.premiseConditions}
                            stock={item.stock ?? undefined}
                            remains={item.remains ?? undefined}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};