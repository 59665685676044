import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X, CheckCircle2, XCircle, ExternalLink } from 'lucide-react';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';

// グローバル型定義は不要(visualViewportは標準のDOM APIの一部)
import { purchaseShopItemAtom, masterDataAtom } from '../../../../atoms';
import { paths } from '../../../../../config/paths';
import { useToast } from '../../../../../components/toast/useToast';
import coinIcon from '../../../../../assets/fujiyamatap-icons/ui/coin.webp';
import tonIcon from '../../../../../assets/fujiyamatap-icons/ui/ton_symbol.svg';

const CURRENCY_ICONS: Record<string, string> = {
    'coin': coinIcon,
    'ton': tonIcon
};

export interface InputField {
    inputId: string;
    typeId: string;
    title: string;
    description: string | null;
    inputType: string;
}

interface PremiseCondition {
    name: string;
    description: string;
    conditionType: string;
    conditionSubjectId: string;
    passed: boolean;
}

type ShopItemConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    icon?: string;
    title: string;
    description: string;
    itemId: string;
    priceId: string;
    amount?: number;
    inputs: InputField[];
    canSelectMultiple: boolean;
    price: {
        unitPrice: number;
        currency: string;
    };
    premiseConditions?: PremiseCondition[];
    stock?: number;
    remains?: number;
};

const ShopItemConfirmationModal = ({
    isOpen,
    onClose,
    icon,
    title,
    description,
    itemId,
    priceId,
    inputs,
    canSelectMultiple,
    price,
    premiseConditions = [],
    stock,
    remains,
}: ShopItemConfirmationModalProps) => {
    const [isClosing, setIsClosing] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [errorFields, setErrorFields] = useState<Set<string>>(new Set());
    const [formValues, setFormValues] = useState<Record<string, string>>({});
    const [amount, setAmount] = useState<string>('');
    const [isKeyboardActive, setIsKeyboardActive] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [, purchaseItem] = useAtom(purchaseShopItemAtom);

    const getDisplayAmount = () => {
        const parsedAmount = parseInt(amount);
        return isNaN(parsedAmount) || parsedAmount < 1 ? 1 : parsedAmount;
    };

    const validateAmount = () => {
        // canSelectMultipleがtrueの場合は常に有効（数量は1固定）
        if (!canSelectMultiple) {
            return true;
        }

        if (amount === '') {
            setError('Please enter the amount');
            return false;
        }
        const parsedAmount = parseInt(amount);
        if (isNaN(parsedAmount) || parsedAmount < 1) {
            setError('Please enter a number greater than or equal to 1');
            return false;
        }
        if (remains !== undefined && parsedAmount > remains) {
            setError(`Please enter a number less than or equal to the remaining amount (${remains})`);
            return false;
        }
        return true;
    };
    const [masterData] = useAtom(masterDataAtom);
    const { showToast } = useToast();

    useEffect(() => {
        if (!isKeyboardActive) {
            setKeyboardHeight(0);
            return;
        }

        const initialHeight = window.innerHeight;
        const checkKeyboardHeight = () => {
            const currentHeight = window.visualViewport?.height || window.innerHeight;
            const keyboardHeight = Math.max(0, initialHeight - currentHeight);
            setKeyboardHeight(keyboardHeight);
        };

        checkKeyboardHeight();
        window.visualViewport?.addEventListener('resize', checkKeyboardHeight);

        return () => {
            window.visualViewport?.removeEventListener('resize', checkKeyboardHeight);
        };
    }, [isKeyboardActive]);

    const allConditionsPassed = premiseConditions.every(condition => condition.passed);

    const getSpecialTaskTitle = (taskId: string) => {
        const specialTask = masterData.specialTasks.find(task => task.taskId === taskId);
        return `Special Task: ${specialTask?.title || taskId}`;
    };

    // Reset form values when modal opens
    useEffect(() => {
        if (isOpen) {
            setFormValues({});
            setError(null);
        }
    }, [isOpen]);

    const handleInputChange = (inputId: string, value: string) => {
        setFormValues(prev => ({
            ...prev,
            [inputId]: value
        }));
    };

    const validateForm = () => {
        const missingFields = inputs.filter(input => !formValues[input.inputId]);
        if (missingFields.length > 0) {
            setError(`Please fill in all required fields`);
            setErrorFields(new Set(missingFields.map(field => field.inputId)));
            return false;
        }

        // メールアドレスのバリデーション
        const emailFields = inputs.filter(input => input.inputType === 'email');
        const invalidEmailFields = emailFields.filter(input => {
            const value = formValues[input.inputId];
            // 基本的なメールアドレスの正規表現パターン
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !emailPattern.test(value);
        });

        if (invalidEmailFields.length > 0) {
            setError(`Please enter a valid email address`);
            setErrorFields(new Set(invalidEmailFields.map(field => field.inputId)));
            return false;
        }

        setErrorFields(new Set());
        return true;
    };

    // Reset form when modal opens
    useEffect(() => {
        if (isOpen) {
            setFormValues({});
            setError(null);
            setErrorFields(new Set());
            setAmount('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setIsMounted(true);
        }
        return () => {
        };
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            setError(null);
            onClose();
            setIsMounted(false);
        }, 300);
    };

    const handleInputInteraction = (e: React.FocusEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
        const input = e.currentTarget;
        setIsKeyboardActive(true);

        // モバイルデバイスでのみ処理を実行
        if (window.visualViewport) {
            const modalContent = input.closest('.modal-content') as HTMLElement;
            if (!modalContent) return;

            // Purchaseボタンまでスクロール
            const purchaseButton = modalContent.querySelector('button[disabled]') as HTMLElement;
            if (purchaseButton) {
                modalContent.scrollTo({
                    top: purchaseButton.offsetTop + purchaseButton.offsetHeight,
                    behavior: 'smooth'
                });
            }
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        handleInputInteraction(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsKeyboardActive(false);
    };

    useEffect(() => {
        if (!isKeyboardActive) {
            setKeyboardHeight(0);
            return;
        }

        const checkKeyboardHeight = () => {
            const windowHeight = window.innerHeight;
            const viewportHeight = window.visualViewport?.height || windowHeight;
            const keyboardHeight = Math.max(0, windowHeight - viewportHeight);
            setKeyboardHeight(keyboardHeight);
        };

        // 初期チェックとリサイズ監視
        checkKeyboardHeight();
        window.visualViewport?.addEventListener('resize', checkKeyboardHeight);

        return () => {
            window.visualViewport?.removeEventListener('resize', checkKeyboardHeight);
        };
    }, [isKeyboardActive]);

    const renderInputField = (input: InputField) => {

        return (
            <div key={input.inputId} className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                    {input.title}
                </label>
                <input
                    type={input.inputType}
                    className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2
                    ${errorFields.has(input.inputId)
                            ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-[#DE6437] focus:border-[#DE6437]'}`}
                    placeholder={input.description || ''}
                    value={formValues[input.inputId] || ''}
                    onChange={(e) => handleInputChange(input.inputId, e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
        );
    };

    const handlePurchase = async () => {
        if (!validateForm() || !validateAmount()) {
            return;
        }

        try {
            setError(null);
            setIsLoading(true);
            await purchaseItem({
                itemId,
                priceId,
                amount: canSelectMultiple ? getDisplayAmount() : 1,
                inputs: inputs.map(input => ({
                    inputId: input.inputId,
                    value: formValues[input.inputId] || ''
                }))
            });
            showToast(
                'Purchase Successful',
                'success',
                'Your purchase has been completed successfully.'
            );
            handleClose();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred during purchase');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isMounted) return null;

    const renderPrice = () => {
        const { unitPrice, currency } = price;
        const currencyIcon = CURRENCY_ICONS[currency];

        return (
            <div className="flex items-center justify-center gap-2">
                {currencyIcon && (
                    <img
                        src={currencyIcon}
                        alt={currency}
                        className="w-5 h-5 object-contain"
                    />
                )}
                <span className="text-lg font-medium font-mplus1p">
                    {(getDisplayAmount() * unitPrice).toLocaleString()} {currency === 'ton' ? 'TON' : 'coins'}
                </span>
            </div>
        );
    };

    return createPortal(
        <div
            className={`fixed inset-0 z-50 flex items-end justify-center sm:items-center
            ${isOpen ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        >
            {/* Backdrop */}
            <div
                className={`absolute inset-0 bg-black ${isOpen && !isClosing ? 'opacity-50' : 'opacity-0'} 
                transition-opacity duration-300`}
                onClick={handleClose}
            />

            {/* Modal */}
            <div
                className="relative w-full sm:max-w-lg bg-white rounded-t-xl sm:rounded-xl modal-content
                max-h-[90vh] overflow-y-auto"
                style={{
                    position: 'fixed',
                    bottom: isKeyboardActive ? `${keyboardHeight}px` : '0',
                    left: '0',
                    right: '0',
                    transform: isOpen && !isClosing ? 'translateY(0)' : 'translateY(100%)',
                    transition: isKeyboardActive ? 'none' : 'all 0.3s ease'
                }}
            >
                {/* Close button */}
                <button
                    onClick={handleClose}
                    className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 
                    transition-colors duration-200"
                >
                    <X className="w-5 h-5 text-gray-500" />
                </button>

                {/* Content */}
                <div className="p-6 space-y-4">
                    {/* Icon */}
                    {icon && (
                        <div className="flex justify-center">
                            <div className="w-32 h-32 flex-shrink-0">
                                <img
                                    src={icon}
                                    alt={title}
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        </div>
                    )}

                    {/* Title */}
                    <h3 className="text-lg font-mplus1p font-semibold text-center text-gray-900">
                        {title}
                    </h3>

                    {/* Description */}
                    <p className="text-sm font-mplus1p text-center text-gray-600">
                        {description}
                    </p>

                    {/* Price Info */}
                    {renderPrice()}

                    {/* Premise Conditions */}
                    {premiseConditions.length > 0 && (
                        <div className="space-y-4">
                            <h4 className="text-sm font-medium text-gray-900">
                                Prerequisites
                            </h4>
                            <div className="space-y-2">
                                {premiseConditions.map((condition, index) => (
                                    <div key={index} className="flex items-center space-x-2">
                                        {condition.passed ? (
                                            <CheckCircle2 className="w-5 h-5 text-green-500" />
                                        ) : (
                                            <XCircle className="w-5 h-5 text-red-500" />
                                        )}
                                        <span className="text-sm">
                                            {condition.conditionType === 'special-task'
                                                ? (
                                                    <Link
                                                        to={paths.tasks.special.task.getHref(condition.conditionSubjectId)}
                                                        className="underline flex items-center gap-1"
                                                    >
                                                        {getSpecialTaskTitle(condition.conditionSubjectId)}
                                                        <ExternalLink className="w-3 h-3" />
                                                    </Link>
                                                )
                                                : condition.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Input Fields */}
                    {inputs.length > 0 && allConditionsPassed && (
                        <div className="space-y-4">
                            <h4 className="text-sm font-medium text-gray-900">
                                Required Information
                            </h4>
                            {inputs.map(input => renderInputField(input))}

                            {/* Amount Input - 複数選択可の場合のみ表示 */}
                            {canSelectMultiple && (
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Amount
                                    </label>
                                    <div>
                                        <input
                                            type="number"
                                            min="1"
                                            max={remains}
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            onFocus={(e) => handleFocus(e)}
                                            onBlur={handleBlur}
                                            required
                                            className="w-full px-3 py-2 border rounded-md shadow-sm
                                            border-gray-300 focus:ring-[#DE6437] focus:border-[#DE6437]"
                                        />
                                        {remains !== undefined && stock !== undefined && (
                                            <p className="mt-1 text-sm text-gray-500">
                                                {remains}/{stock}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Not Passed Message */}
                    {!allConditionsPassed && (
                        <p className="text-sm text-red-600 text-center">
                            Cannot purchase because prerequisites are not met
                        </p>
                    )}

                    {/* Error Message */}
                    {error && (
                        <p className="text-sm text-red-600 text-center">
                            {error}
                        </p>
                    )}

                    {/* Confirm Button */}
                    <button
                        onClick={handlePurchase}
                        disabled={isLoading || !allConditionsPassed}
                        className="w-full py-3 px-4 text-white rounded-lg
                        font-medium transition-colors duration-200
                        bg-[#DE6437] hover:bg-[#C55832] focus:outline-none focus:ring-2
                        focus:ring-[#DE6437] focus:ring-offset-2 font-mplus1p
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center space-x-2">
                                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                <span>Processing...</span>
                            </div>
                        ) : (
                            'Purchase'
                        )}
                    </button>
                </div>

                {/* Bottom safe area spacing for mobile */}
                <div className="h-[env(safe-area-inset-bottom)]" />
            </div>
        </div>,
        document.body
    );
};

export default ShopItemConfirmationModal;
